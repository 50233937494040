
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import IPhoneProp from "../components/IPhoneProp";
import TextWave from "../components/TextWave";

import "./home.css"
const Home = () => {
  return (
    <>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <IPhoneProp/>
            <div>
            <span className="font-span">
            Hotter than the average sasquatch?
            
            </span>
            </div>
            <div>
            <span className="font-span">
            Try our app and find out!

            </span>
            </div>

            <div className="features">

            <div className="features-container">
              <div className="feature-box">
                  <div className="icon"></div>
                  <h3>Easy-Peasy Interface</h3>
                  <p>With an interface so intuitive, even the average sasquatch can use it.</p>
              </div>
              <div className="feature-box">
                  <div className="icon"></div>
                  <h3>No Nonsense Voting</h3>
                  <p>Our fancy algorithms weed out the jokesters so we don’t end up with a pineapple pizza as top choice.</p>
              </div>
            </div>

            <div className="features-container">
              <div className="feature-box">
                  <div className="icon"></div>
                  <h3>Rank ‘Em and Spank ‘Em</h3>
                  <p>Climb the ranks locally or globally, and find out if you’re the hottest thing on the internet.</p>
              </div>
              <div className="feature-box">
                  <div className="icon"></div>
                  <h3>Challenge Accepted!</h3>
                  <p>Put your bravery to the test by challenging friends and family, Who will reign supreme... and who will sleep on the couch tonight?</p>
              </div>

            </div>
            </div>

        </div>
    </>
 
  );
};

export default Home;