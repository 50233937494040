
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import IPhoneProp from "../components/IPhoneProp";
import "./home.css"
const Tos = () => {
  return (
    <>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'left', fontSize:'18px'}}>
            
            <div style={{width:'60%'}}>

        
        <h1>**Terms of Use Agreement**</h1>

        <h1>**Last Updated: 2024-09-29**</h1>

        <p>Welcome to Snyggster (“we”, “us”, “our”). This Terms of Use Agreement (“Agreement”) governs your use of our app and services. By accessing, downloading, using, or uploading content to our app, you agree to be bound by this Agreement. If you do not agree with any part of this Agreement, please refrain from using our app and services.</p>
        <h1>
        **1. User Accounts and Eligibility**
        </h1>
        <p><strong>1.1. **Eligibility**:</strong> You must be at least 18 years of age to create an account and use our app. By registering, you represent and warrant that you are at least 18 years old.
        </p>
        <p>1.2. **Account Security**: As a password-free solution, we utilize secure authentication methods to protect your account. You are responsible for ensuring the security of your authentication method and for all activities that occur under your account.
        </p>
        <h1>**2. User Content**</h1>
        <p>
        2.1. **Ownership**: You retain all rights to any images and other content you upload to Snyggster (“User Content”).
        </p>
        <p>
        2.2. **License to Snyggster**: By uploading User Content, you grant us a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use, display, reproduce, modify, distribute, and perform such content for purposes including, but not limited to, advertisement, marketing, and display on our homepage.
        </p>
        <p>
        2.3. **Privacy of Images and Scores**: Images uploaded will be seen by other users for rating purposes within the app. However, the scores associated with images remain private unless you choose to submit the image for ranking. Once submitted for ranking, the images and their associated scores will be displayed publicly on our homepage.
        </p>
        <h1>
        **3. Use of Images**
        </h1>
        <p>
        3.1. **Advertisement**: We reserve the right to use any submitted images for advertisement and promotional purposes on our homepage, social media channels, or other marketing collateral.
        </p>
        <p>
        3.2. **Legal Purposes**: We may retain and disclose your images and account information if required to do so by law or in good faith belief that such preservation or disclosure is reasonably necessary to comply with legal process, enforce this Agreement, or protect the rights, property, or safety of Snyggster, our users, and the public.
        </p>
        <h1>
        **4. Prohibited Conduct**
        </h1>
        <p>
        4.1. **Illegal Activities**: You agree not to use the app for any unlawful or prohibited purpose, including but not limited to harassment, abuse, or violating any local, state, national, or international law.
        </p>
        <p>
        4.2. **Content Restrictions**: You may not upload any User Content that is defamatory, obscene, offensive, or infringes on any third party’s rights, including intellectual property rights.
        </p>
        <h1>
        **5. Disclaimer of Warranties**
        </h1>
        <p>
        5.1. **Use at Your Own Risk**: Your use of the app is at your own risk. The app is provided on an “as is” and “as available” basis. We disclaim all warranties, express or implied, including the warranties of merchantability, fitness for a particular purpose, and non-infringement.
        </p>
        <h1>**6. Limitation of Liability**
        </h1>
        <p>
        6.1. **No Liability for User Content**: We are not liable for any User Content you upload or any harm resulting from the public display of your images.
        </p>
        <p>
        6.2. **Maximum Liability**: To the maximum extent permitted by law, in no event shall Snyggster or its affiliates be liable for any indirect, incidental, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly.
        </p>
        <h1>
        **7. Indemnification**
        </h1>
        <p>7.1. **Indemnity**: You agree to indemnify and hold harmless Snyggster and its affiliates from and against any and all claims, liabilities, damages, losses, and expenses, including legal fees, arising out of or in any way connected with your access to or use of the app or your violation of this Agreement.</p>
        <h1>
        **8. Modifications to the Agreement**
        </h1>
        <p>8.1. **Changes**: We reserve the right to modify this Agreement at any time. Any changes will be effective immediately upon posting to the app. Your continued use of the app after any such changes constitutes your acceptance of the new terms.</p>
        <h1>
        **9. Termination**
        </h1>
        <p>9.1. **Termination by Us**: We may terminate or suspend your account and access to the app at our sole discretion, without notice, for conduct that we believe violates this Agreement or is harmful to other users of the app, us, or third parties.</p>
        <h1>
        **10. Governing Law**
        </h1>
        <p>10.1. **Jurisdiction**: This Agreement shall be governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law principles.
        </p>

        By using Snyggster, you acknowledge that you have read, understood, and agree to be bound by this Agreement.

        </div>
        </div>
    </>
 
  );
};

export default Tos;