
/*
import { React, useState, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import IPhoneProp from "../components/IPhoneProp";
import "./beta.css"; // Updated the CSS file name to match the component
import baseUrl from "../urlenv";
import ReCAPTCHA from 'react-google-recaptcha';

const Beta = () => {
  const captchaSiteKey =process.env.REACT_APP_API_CAPTCHA_PUBLIC;

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [status, setStatus] = useState({ message: '', success: null, loading: false });

  const recaptcha = useRef();
  const nameRef = useRef(null);
  const emailRef = useRef(null);

  async function submitForm(event) {
    event.preventDefault();

    const name = nameRef.current.value;
    const email = emailRef.current.value;

    const params = new URLSearchParams();
    params.append('name', name);
    params.append('email', email);

    const captchaValue = recaptcha.current.getValue();

    if (!captchaValue) {
      alert('Please verify the reCAPTCHA!');
    } else {
      setStatus({ success: null, loading: true });

      const res = await fetch(`${baseUrl.apiUrl}/recaptcha`, {
        method: 'POST',
        body: JSON.stringify({ captchaValue }),
        headers: {
          'content-type': 'application/json',
        },
      });

      const data = await res.json();

      if (data.success) {
        fetch(`${baseUrl.apiUrl}/registerMail`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: params.toString(),
        })
        .then((response) => response.json())
        .then((data) => {
          setStatus({
            success: data.success,
            loading: false,
            message: data.success
              ? 'Successfully added to our mailing list!'
              : 'Failed to add to list, is your email already registered?',
          });
          recaptcha.current.reset();
        })
        .catch((error) => {
          setStatus({
            success: false,
            loading: false,
            message: 'Wopsie! There was an error while trying to access our server',
          });
          console.log('Fetch Error:', error);
        });
      }
    }
  }


  return (
    <div className="beta-container">
      <div>
        <h1>Get notified when beta is released!</h1>
        {!status.loading && (
          <h2 className={status.success ? "message-success" : "message-error"}>
            {status.message}
          </h2>
        )}

        {!status.success && (
          <form onSubmit={submitForm}>
            <div className="form-container">
              <input
                name="Email"
                type="email"
                value={email}
                ref={emailRef}
                required
                placeholder="joe@example.com"
                onChange={(event) => setEmail(event.target.value)}
                className="input-field"
              />
              <input
                name="Name"
                type="name"
                value={name}
                ref={nameRef}
                required
                placeholder="Joe"
                onChange={(event) => setName(event.target.value)}
                className="input-field"
              />
              <button type="submit" className="submit-button">
                Sign up
              </button>
            </div>

            <div className="recaptcha-container">
              <ReCAPTCHA ref={recaptcha} sitekey={captchaSiteKey} />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Beta;

*/


import { React, useState, useRef } from "react";
import "./beta.css"; // Updated the CSS file name to match the component
import baseUrl from "../urlenv";
import ReCAPTCHA from 'react-google-recaptcha';


const Beta = () => {
  const captchaSiteKey = process.env.REACT_APP_API_CAPTCHA_PUBLIC;

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [status, setStatus] = useState({ message: '', success: null, loading: false });
  const [showRecaptcha, setShowRecaptcha] = useState(false);

  const recaptcha = useRef();
  const nameRef = useRef(null);
  const emailRef = useRef(null);

  async function submitForm(event) {
    event.preventDefault();

    if (!showRecaptcha) {
      setShowRecaptcha(true);
      return;
    }

    const name = nameRef.current.value;
    const email = emailRef.current.value;

    const params = new URLSearchParams();
    params.append('name', name);
    params.append('email', email);

    const captchaValue = recaptcha.current.getValue();

    if (!captchaValue) {
      alert('Please verify the reCAPTCHA!');
    } else {
      setStatus({ success: null, loading: true });


        fetch(`${baseUrl.apiUrl}/recaptcha`, {
          method: 'POST',
          body: JSON.stringify({ captchaValue }),
          headers: {
            'content-type': 'application/json',
          },
        }).then((response) => response.json())
        .then((data) => {

          if (data.success) {
            fetch(`${baseUrl.apiUrl}/registerMail`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: params.toString(),
            })
            .then((response) => response.json())
            .then((data) => {
              setStatus({
                success: data.success,
                loading: false,
                message: data.success
                  ? 'You have been added to our mailing list!'
                  : 'Failed to add to list, is your email already registered?',
              });
              recaptcha.current.reset();
              setShowRecaptcha(false);
            })
            .catch((error) => {
              setStatus({
                success: false,
                loading: false,
                message: 'Wopsie! There was an error while trying to access our server',
              });
              console.log('Fetch Error:', error);
              setShowRecaptcha(false);
            });
          } else {
            setStatus({
              success: false,
              loading: false,
              message: 'ReCAPTCHA Verification failed.',
            });
            setShowRecaptcha(false);
          }

        }).catch((error) => {

          setStatus({
            success: false,
            loading: false,
            message: 'Wopsie! There was an error while trying to access our server',
          });
          console.log('Fetch Error:', error);
          setShowRecaptcha(false);
        });
      }
    }

  return (
    <div className="beta-container">
      <div>
      
        <h3>Sign up to our mailing list and get notified when beta is released!</h3>
        {!status.loading && (
          <h2 className={status.success ? 'message-success' : 'message-error'}>
            {status.message}
          </h2>
        )}

        {!status.success && (
          <form onSubmit={submitForm}>
            
            <div className="form-container">
               <input
                name="Email"
                type="email"
                value={email}
                ref={emailRef}
                required
                placeholder="email@example.com"
                onChange={(event) => setEmail(event.target.value)}
                className="input-field"
              />
              <input
                name="Name"
                type="text"
                value={name}
                ref={nameRef}
                required
                placeholder="my name"
                onChange={(event) => setName(event.target.value)}
                className="input-field"
              />
              <button type="submit" className="submit-button">
                {showRecaptcha ? 'Verify ReCAPTCHA' : 'Sign up'}
              </button>
            </div>

            {showRecaptcha && (
              <div className="recaptcha-container">
                <ReCAPTCHA ref={recaptcha} sitekey={captchaSiteKey} />
              </div>
            )}
          </form>
        )}
      </div>
    </div>
  );
};

export default Beta;

