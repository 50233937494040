
import React from 'react';

const Footer = () => {



  return (
    <footer style={{ background: "#181818", color: "#b4b4b4", padding: "20px", textAlign: "center" }}>
      <p>© 2023 Snyggster. All rights reserved.</p>
      <div style={{ marginBottom: "10px" }}>
        {/* Add your quick links here */}
        <a href="/about" style={{ color: "#b4b4b4", margin: "0 10px" }}>About Us</a> |
        <a href="/contact" style={{ color: "#b4b4b4", margin: "0 10px" }}>Contact</a> |
        <a href="/pp" style={{ color: "#b4b4b4", margin: "0 10px" }}>Privacy Policy</a> |
        <a href="/tos" style={{ color: "#b4b4b4", margin: "0 10px" }}>Terms of Service</a>
      </div>
      {/*
      <div style={{ marginBottom: "10px" }}>
        Follow us on:
        <a href="https://www.facebook.com" style={{ margin: "0 10px" }} aria-label="Facebook">
          <img src="path/to/facebook-icon.png" alt="Facebook" style={{ width: "24px" }} />
        </a>
        <a href="https://www.twitter.com" style={{ margin: "0 10px" }} aria-label="Twitter">
          <img src="path/to/twitter-icon.png" alt="Twitter" style={{ width: "24px" }} />
        </a>
        <a href="https://www.instagram.com" style={{ margin: "0 10px" }} aria-label="Instagram">
          <img src="path/to/instagram-icon.png" alt="Instagram" style={{ width: "24px" }} />
        </a>
        <a href="https://www.linkedin.com" style={{ margin: "0 10px" }} aria-label="LinkedIn">
          <img src="tiktokIcon.png" alt="LinkedIn" style={{ width: "24px" }} />
        </a>
      </div>
      <p>Contact: <a href="mailto:contact@yourcompany.com" style={{ color: "#b4b4b4" }}>contact@yourcompany.com</a></p>
      */}
    </footer>
  );
};

export default Footer;