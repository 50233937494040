
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import IPhoneProp from "../components/IPhoneProp";
import "./stats.css"
const Stats = () => {
  return (
    <>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            
            <div style={{display:'flex', flexDirection:'column', textAlign:'left'}}>
            <span className="joke-text">
            Behind every great statistic...
            </span>
            <span className="joke-text">
            There's a curvy data set that keeps things in line!
            </span>
            
            </div>
            
        </div>
    </>
 
  );
};

export default Stats;