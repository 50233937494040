
const config = {
  development: {
    apiUrl: 'http://127.0.0.1:5001/snyggster-firebase/us-central1/api',
  },
  production: {
    apiUrl: 'https://us-central1-snyggster-firebase.cloudfunctions.net/api',
  },
};

// Determine the current environment
const environment = process.env.NODE_ENV || 'development';

// Export the configuration for the current environment
module.exports = config[environment];