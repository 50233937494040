
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./header";
import HomePage from "./pages/home";
import StatsPage from "./pages/stats";
import AboutPage from "./pages/about";
import BetaPage from "./pages/beta";
import RankedPage from "./pages/ranked";
import Footer from "./footer";
import TosPage from "./pages/ToS";
import ContactPage from "./pages/contact";


const App = () => {
  return (
    <Router>
      <div style={{background: "#121212", color:"#b4b4b4", display: "flex", flexDirection: "column", minHeight: "100vh" }}>
        <Header />
        <div style={{ flex: "1", padding: "20px" }}>
          <Routes>
            <Route path="/" element={<HomePage/>} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/beta" element={<BetaPage />} />
            <Route path="/stats" element={<StatsPage />} />
            <Route path="/contact" element={<ContactPage />} />

            {/*<Route path="/ranked" element={<RankedPage />} /> */}
            <Route path="/tos" element={<TosPage />} />
  
          </Routes>
        </div>
        <Footer/>
      </div>
    </Router>
  );
};

export default App;