import {React, useEffect,useState} from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import IPhoneProp from "../components/IPhoneProp";
import "./ranked.css"

const baseUrl = require('../urlenv');


const Ranked = () => {

  const [rankedList, setRankedList] = useState(new Map());
  const [rankedUrl, setRankedUrl] = useState(`${baseUrl.apiUrl}/getRanked`);

  const fetchRanked = (start) => {


    fetch(`${rankedUrl}/${start}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success){
          for (var k in data.ranked){         
            rankedList.set(data.ranked[k].id,data.ranked[k]);
            
          }
          setRankedList(new Map(rankedList));
          
        }else{
          console.log('failed getting ranked list!');
        }
       
      })
      .catch((error) => console.log(error));

  };


  useEffect(() => {
  
    fetchRanked(0);
      console.log('Fetched!');
  }, []);

  console.log('Ranked()');

  return (
    <>
      <span className="title-text">
      Who is the fairest of them all?
      </span>
      <span className="note-text">
      * Images bellow are temporarly random images untill beta is released. 
      </span>
      
      <div className="card-container">
      {Array.from(rankedList).map(([key, value], index) => (
        <div className="card" key={key}>
          <img src={value.image} alt={`Ranked ${value.rank}`} />
          <div className="card-content">
            <span>#{value.rank}</span>
          </div>
        </div>
      ))}
          
    </div>

    <div className="button-container">
      <div className="fetchMore-button">
        <span onClick={() => fetchRanked(rankedList.size)}>Get more</span>
      </div>
    </div>

    </>
 
  );
};


export default Ranked;