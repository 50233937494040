
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import IPhoneProp from "../components/IPhoneProp";
import "./about.css"

const About = () => {
  return (
    <>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'left', fontSize:'18px'}}>
            
            <div style={{width:'60%'}}>

            <h1 className="title">About Our Service</h1>
    <p>Welcome to <strong>Snyggster</strong>, where we aim to provide an honest and objective appraisal of your physical appearance. Understanding one's own physical attributes allows for growth, self-improvement, and a more realistic expectation in the realms of social interactions and relationships. Our philosophy is not about diminishing your self-confidence, but rather offering an accurate perspective that can be both enlightening and beneficial.</p>

    <p>In the modern age of social media, the perception of one's own looks can often become skewed by a constant stream of likes, comments, and filters. This can lead to an inflated sense of self or, conversely, a detachment from reality. Our service seeks to provide a balance by offering an unbiased rating that friends and family might shy away from giving. Remember, there's no shame in being a 4, or even a 1. The goal is to understand where you truly stand in order to set realistic expectations and avoid the pitfalls of a delusional self-image.</p>

    <p>We employ a fun and engaging approach to this process, ensuring that you can take away valuable insights in an amusing and enjoyable manner. This is not just about finding areas for improvement but also about celebrating unexpected results—some may discover that they rank higher than they initially believed.</p>

    <p>Ultimately, <strong>Snyggster</strong> is here to offer a clear-eyed and sober view of your appearance, helping you navigate the complexities of personal image in a world that often blurs the lines between reality and perception.</p>

    <p>Join us in embracing an honest and realistic understanding of physical beauty, and use this insight to enhance your self-awareness and personal growth.</p>
            </div>
            
        </div>
    </>
 
  );
};

export default About;