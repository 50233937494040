
/*
import React, { useState, useEffect, useRef } from 'react';
import './IPhoneProp.css';

const lerp = (start, end, t) => {
  return start * (1 - t) + end * t;
};

const IPhone16Prop = () => {
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [targetPosition, setTargetPosition] = useState({ top: 0, left: 0 });
  const requestRef = useRef();

  const centerPosition = { top: -500, left: -250};
  const maxOffset = 100; // Maximum distance from center

  const getRandomPosition = () => {
    return {
      top: centerPosition.top + (Math.random() - 0.5) * maxOffset,
      left: centerPosition.left + (Math.random() - 0.5) * maxOffset,
    };
  };

  const animate = () => {
    setPosition(prevPosition => {
      const newTop = lerp(prevPosition.top, targetPosition.top, 0.05);
      const newLeft = lerp(prevPosition.left, targetPosition.left, 0.05);
      

      return { top: newTop, left: newLeft };
    });

    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    setTargetPosition(getRandomPosition());

    const intervalId = setInterval(() => {
      setTargetPosition(getRandomPosition());
    }, 3000); // Change target position every 3 seconds

    requestRef.current = requestAnimationFrame(animate);

    return () => {
      clearInterval(intervalId);
      cancelAnimationFrame(requestRef.current);
    };
  }, []);

  return (
    <div className="image-container">
      <img
        src="IPhone16.png"
        alt="base"
        className="base-image"
      />
      <div className="inner-div">
        <img
          src="confused.jpg"
          alt="overlay"
          className="overlay-image"
          style={{
            transform: `translate(${position.left}px, ${position.top}px) scale(0.8)`,
          }}
        />
      </div>
    </div>
  );
};

export default IPhone16Prop;
*/

import React, { useState, useEffect } from 'react';
import './IPhoneProp.css';

const IPhone16Prop = () => {
  const [position, setPosition] = useState({ top: -500, left: -500 });
  const [scale, setScale] = useState(0.6);

  const getRandomPosition = () => {
    const maxOffset = 100;
    const minOffset = -0;
    return {
      top:  position.top+ Math.random() * 100-50,
      left: position.left+Math.random() * 200-100,
    };
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      //setPosition(getRandomPosition());
    }, 500); // Change position every 3 seconds

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="image-container">
      <img
        src="IPhone16.png"
        alt="base"
        className="base-image"
      />
      <div className="inner-div">
        <img
          src="confused.jpg"
          alt="overlay"
          className="overlay-image"
          style={{
            transform: `translate(${position.left}px, ${position.top}px) scale(${scale})`,
          }}
        />
      </div>
    </div>
  );
};

export default IPhone16Prop;

