
import React from "react";
import { Link, NavLink } from "react-router-dom";

import "./header.css";


const Header = () => {
  return (
    <div className="header-container">
      
      <div className="header-content">
        <span className="logo">Snyggster</span>
        <div className="navigation">
          <nav>
            <NavLink to="/" className={({ isActive }) => isActive ? "link-selected" :  "link-normal"} end>
              Home
            </NavLink>
            <NavLink to="/about" className={({ isActive }) => isActive ? "link-selected" : "link-normal"}>
              About
            </NavLink>
            <NavLink to="/beta" className={({ isActive }) => isActive ? "link-selected" : "link-normal"}>
              Beta
            </NavLink>
            <NavLink to="/contact" className={({ isActive }) => isActive ? "link-selected" : "link-normal"}>
              Contact
            </NavLink>
            <NavLink to="/stats" className={({ isActive }) => isActive ? "link-selected" : "link-normal"}>
              Stats
            </NavLink>
            {/*
            <NavLink to="/ranked" className={({ isActive }) => isActive ? "link-selected" : "link-normal"}>
              Ranked
            </NavLink>
            */}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Header;